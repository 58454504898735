.navbar {
  background-color: black;
  display: flex;
  height: 80px;
  justify-content: center;
  align-items: center;
  font-size: 1.2rem;
  position: sticky;
  top: 0;
  z-index: 999;
  width: 100%;
  max-width: 1500px;
  padding-left: 10%;
  padding-right: 10%;
  margin: auto;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 1500px;
  width: 100%;
}

.navbar-logo {
  width: 100%;
  color: #fff;
  justify-self: left;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  float: left;
  font-size: 1.5rem;
  align-items: left;
}
.navbar-img {
  width: 30px;
  height: 30px;
}

.menu-icon {
  cursor: pointer;
  float: right;
  width: 0px;
  font-size: 1.5rem;
  padding-right: 2%;
  animation: slide-down 0.3s;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  /* justify-content: center; */
  margin-right: 2rem;
}

.nav-item {
  font-size: 2rem;
}

.nav-links {
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
  font-size: 1.75rem;
}

.nav-links:hover {
  border-bottom: 4px solid #fff;
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #fff;
}

.nav-links-mobile {
  display: none;
}

.social-icons {
  font-size: 2rem;
  color: white;
  padding-left: 1%;
}

/* @media screen and (max-width: 960px) { */
.NavbarItems {
  position: relative;
}

.nav-menu {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 80px;
  left: -100%;
  opacity: 1;
  transition: all 0.5s ease;
}

.nav-menu.active {
  background: black;
  max-width: 100%;
  margin: auto;
  padding-left: 10%;
  padding-right: 10%;
  left: 0;
  opacity: 0.9;
  transition: all 0.5s ease;
  z-index: 1;
}

.nav-links {
  text-align: center;
  padding: 2rem;
  width: 100%;
  display: table;
}

.nav-links:hover {
  background-color: #fff;
  color: #242424;
  border-radius: 0;
}

.fa-times {
  color: #fff;
  font-size: 2rem;
}

.nav-links-mobile {
  display: block;
  text-align: center;
  margin: 2rem auto;
  border-radius: 4px;
  width: 80%;
  text-decoration: none;
  font-size: 1.5rem;
  background-color: transparent;
  color: #fff;
  padding: 14px 20px;
  border: 1px solid #fff;
  transition: all 0.3s ease-out;
}

.nav-links-mobile:hover {
  background: #fff;
  color: #242424;
  transition: 250ms;
}
