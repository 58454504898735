.btn1 {
  background: black;
  border: 2px solid white;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 1rem;
  transition: all 0.5s;
  width: auto;
  position: relative;
}

.btn1:hover {
  transition: all 0.5s;
  opacity: 0.8;
  font-size: 1.5rem;
  color: #ffffff;
}

.btn2 {
  background: black;
  border: 2px solid white;
  color: #ffffff;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 1rem;
  transition: all 0.5s;
  width: auto;
  position: relative;
}

.btn2:hover {
  transition: all 0.5s;
  opacity: 0.8;
  font-size: 1.5rem;
  color: #ffffff;
}

.btn3 {
  background: none;
  font-weight: 700;
  border: none;
  color: whitesmoke;
  cursor: pointer;
  font-size: 1.25rem;
  padding: 0.5rem;
  width: auto;
  position: relative;
  text-decoration: underline;
}

.btn3:hover {
  opacity: 0.8;
  color: black;
}
