html {
  font-family: stolzl, sans-serif;
  font-weight: 300;
  font-style: normal;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.ft {
  margin-top: 20% !important;
}
