.res-div {
  width: 100%;
  max-width: 1500px;
  padding-left: 10%;
  padding-right: 10%;
  margin: auto;
}

.r-img {
  width: 100%;
}
