.about-me {
  width: 100%;
  max-width: 1500px;
  padding-left: 10%;
  padding-right: 10%;
  margin: auto;
}

.s-img {
  width: 40%;
  height: 100%;
  max-width: 1500px;
  float: right;
  margin: 0% 0% 5% 5%;
  border-radius: 25px;
}

.a-title {
  font-size: 50px;
  padding: 5% 10% 5% 0%;
}

.a-text {
  font-size: 1.5rem;
  letter-spacing: 0.2rem;
}

@media screen and (max-width: 800px) {
  .s-img {
    width: 100%;
  }
}
